import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Facebook from './Facebook'
import Twitter from './Twitter'

const query = graphql`
  query SEO {

    logo: file(
      relativeDirectory: {eq: ""},
      name: {eq: "logoV1"}
    ) {
      publicURL
    }

    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        siteName
        siteLanguage
        ogLanguage
        twitterUsername
        rating
        copyright
        contactEmail
      }
    }
  }
`

const SEO = ({ schemas = [], breadcrumb, title, description, keywords, imagePath, imageAlt, pagePath, robots }) => {
  const { site, logo } = useStaticQuery(query)

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      siteName,
      siteLanguage,
      ogLanguage,
      twitterUsername,
      rating,
      copyright
    },
  } = site

  const canonicalUrl = `${siteUrl}${pagePath}`

  return (
    <>
      <Helmet title={title}>
        <html lang={siteLanguage} />

        <meta name='rating' content={rating} />
        <meta name='robots' content={robots} />
        <meta name='description' content={description} />
        <meta name='keywords' content={keywords}/>
        <meta name='copyright'content={copyright} />
        <meta name='language' content={siteLanguage} />
        <meta name='revised' content={buildTime} />

        <link rel='canonical' href={canonicalUrl} />
        <link rel='logo' type='image/png' href={logo.publicURL} />

        <link rel='apple-touch-icon' sizes='180x180' href='/assets/icons/apple-touch-icon.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/assets/icons/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/assets/icons/favicon-16x16.png' />
        <link rel='manifest' href='/assets/icons/site.webmanifest' />
        <link rel='mask-icon' href='/assets/icons/safari-pinned-tab.svg' color='#f15759' />
        <meta name='msapplication-TileColor' content='#212121' />
        <meta name='theme-color' content='#212121' />

        {/* https://stackoverflow.com/questions/47875337/dns-prefetch-and-preconnect-one-or-both-fallback */}
        <link rel='preconnect dns-prefetch' href='https://www.google-analytics.com' crossorigin />
        <link rel='preconnect dns-prefetch' href='https://ipapi.co' crossorigin />

        {schemas.map((schema, idx) => <script key={idx} type='application/ld+json'>{JSON.stringify(schema)}</script>)}
        {breadcrumb && <script type='application/ld+json'>{JSON.stringify(breadcrumb)}</script>}
      </Helmet>
      <Facebook
        locale={ogLanguage}
        url={canonicalUrl}
        siteName={siteName}
        type={'website'}
        title={title}
        description={description}
        imageUrl={`${siteUrl}${imagePath}`}
        imageAlt={imageAlt}
      />
      <Twitter
        type={'summary'}
        username={twitterUsername}
        imageAlt={imageAlt}
      />
    </>
  )
}

export default SEO
