import React from 'react'
import { Helmet } from 'react-helmet'

const Facebook = ({ url, siteName, type, title, description, imageUrl, imageAlt, locale }) => (
  <Helmet>
    <meta property="og:site_name" content={siteName} />
    <meta property="og:locale" content={locale} />
    <meta property="og:url" content={url} />
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={imageUrl} />
    <meta property="og:image:alt" content={imageAlt} />
  </Helmet>
)

export default Facebook