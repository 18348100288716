import React from 'react'
import { Helmet } from 'react-helmet'

const Twitter = ({ type, username, imageAlt }) => (
  <Helmet>
    <meta name="twitter:site" content={username} />
    <meta name="twitter:creator" content={username} />
    <meta name="twitter:card" content={type} />
    <meta name="twitter:image:alt" content={imageAlt} />
  </Helmet>
)

export default Twitter